import React from 'react';
import { Button as TPAButton, ButtonProps } from 'wix-ui-tpa/Button';

import { st, classes } from './Button.st.css';
import { settingsParams } from '../../../../common/settings/groups-list/settings';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Theme } from '../../../../common/context/theme';

const ButtonComponent: React.FC<ButtonProps & Theme> = ({ ...props }) => {
  const settings = useSettings();
  const buttonType = settings.get(settingsParams.buttonType);
  const { isMobile } = useEnvironment();
  return (
    <TPAButton
      {...props}
      className={st(
        classes.root,
        {
          bw: props.forceBlackAndWhite,
          type: buttonType,
          mobile: isMobile,
        } as any,
        props.className,
      )}
      upgrade
    />
  );
};

export const Button = ButtonComponent;
Button.displayName = 'Button';
