import React from 'react';
import { AppToastsProvider } from '../../../../common/components/AppToats/AppToastsContext';

import { App } from './App';
import { GroupsWidgetWithStyles } from './types';
import { GroupsAppProvider } from '../../contexts/GroupsAppProvider';
import { MemberInvitesProvider } from '../../../../common/context/member-invites/MemberInvitesContext';

class ApplicationRoot extends React.Component<GroupsWidgetWithStyles> {
  static displayName = 'ApplicationRoot';
  componentDidMount() {
    this.reportAppLoaded();
  }

  render() {
    const { toasts, memberInvitesLink, loadMemberInvitesLink } = this.props;

    return (
      <GroupsAppProvider {...this.props}>
        <AppToastsProvider value={toasts}>
          <MemberInvitesProvider
            value={{ memberInvitesLink, loadMemberInvitesLink }}
          >
            <App />
          </MemberInvitesProvider>
        </AppToastsProvider>
      </GroupsAppProvider>
    );
  }

  private reportAppLoaded() {
    if (!this.props.reportAppLoaded) {
      return;
    }
    try {
      this.props.host.registerToComponentDidLayout(this.props.reportAppLoaded);
    } catch (e) {
      console.log('Error in ApplicationRoot.reportAppLoaded');
    }
  }
}

export default ApplicationRoot;
